import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { GRADUATION_TYPE } from '@/const/status'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    dataListsGraduationGrading: [],
    totalRows: 0,
  },
  getters: {
    dataLists: state => state.dataLists,
    dataListsGraduationGrading: state => state.dataListsGraduationGrading,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_DATA: (state, { records }) => {
      state.dataLists = records
    },
    SET_DATA_GRADUATION_GRADING: (state,  records) => {
      state.dataListsGraduationGrading = records
    },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/graduations/graduation-class', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            records: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async graduationGrading({ commit }, params) {
      try {
        const response = await axios.post('mq/graduationGrading', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataGraduationGrading({ commit }, params) {
      try {
        const response = await axios.get('uni/students/getStudentsGraduationByCourse', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_GRADUATION_GRADING', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
