import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataSources: [],
    courses: [],
    total: 0,
    totalRows: 0,
    maxOrderNo: 0,
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
    maxOrderNo: state => state.maxOrderNo,
    courses: state => state.courses,
    total: state => state.total,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataSources = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => { state.maxOrderNo = maxOrderNo },
    SET_COURSES: (state, courses) => { state.courses = courses },
    SET_TOTAL: (state, total) => { state.total = total },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/graduate-dissertation', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async saveDataSource({ commit }, params) {
      try {
        const response = await axios.post('uni/graduate-dissertation', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`uni/graduate-dissertation/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourses({ commit }, params) {
      try {
        const response = await axios.get('uni/courses', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_COURSES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
